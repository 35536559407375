import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { client } from "./constants";
import { getToken } from "./auth";
import { usePostHog } from "posthog-js/react";
import { UpdateUserInput } from "@/gql/gen";

export const USER_QUERY_KEY = "currentUser";

export const useGetAuth = () => {
  return useQuery({
    queryKey: ["auth"],
    queryFn: async () => {
      return await getToken();
    },
  });
};

export const useGetCurrentUserOptions = () => {
  const posthog = usePostHog();

  return queryOptions({
    queryKey: [USER_QUERY_KEY],
    queryFn: async () => {
      const token = await getToken();
      const user = await client.getUser(undefined, {
        authorization: token.token,
      });


      if (!token.impersonating) {
        posthog.identify(user.users.get.id, user.users.get);
      }

      return user
    },
    select: (u) => {
      return u.users.get;
    },
  });
};

export const useGetCurrentUser = () => {
  return useQuery(useGetCurrentUserOptions());
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (input: UpdateUserInput) => {
      const token = await getToken();
      return client.updateUser(
        { input },
        {
          authorization: token.token,
        },
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_QUERY_KEY],
      });
    },
  });
};

export const useGetBillingPortalURL = () => {
  return useMutation({
    mutationFn: async () => {
      const token = await getToken();
      return client.getBillingPortalURL(undefined, {
        authorization: token.token,
      });
    },
  });
};
