"use client";

import { cookieExists } from "@/api/auth";
import { buttonVariants } from "@/components/ui/button";
import {
	NavigationMenu,
	NavigationMenuContent,
	NavigationMenuItem,
	NavigationMenuLink,
	NavigationMenuList,
	NavigationMenuTrigger,
	navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Separator } from "@/components/ui/separator";
import { ModeToggle } from "@/components/ui/theme-toggle";
import { cn } from "@/lib/utils";
import { motion, useScroll } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

import { ArrowRightIcon } from "lucide-react";
import Head from "next/head";
import Image from "next/image";
import React from "react";
import garmin from "../../images/garmin.png";
import activityDark from "../../images/landing/activity-dark.png";
import activityLight from "../../images/landing/activity-light.png";
import calDark from "../../images/landing/cal-dark.png";
import calLight from "../../images/landing/cal-light.png";
import u1 from "../../images/landing/u1.png";
import u2 from "../../images/landing/u2.png";
import u3 from "../../images/landing/u3.png";
import u4 from "../../images/landing/u4.png";
import u5 from "../../images/landing/u5.png";
import strava from "../../images/strava.png";
import wahoo from "../../images/wahoo.png";
import whoop from "../../images/whoop.png";
import LiveChatScript from "./chat";

export default function LandingPage() {
	const router = useRouter();
	const [smallNav, setSmallNav] = useState(false);
	const { scrollYProgress } = useScroll();

	useEffect(
		() =>
			// eslint-disable-next-line @typescript-eslint/no-deprecated
			scrollYProgress.onChange((latest) => {
				setSmallNav(latest > 0);
			}),
		[scrollYProgress],
	);

	useEffect(() => {
		if (cookieExists()) {
			router.push("/overview");
		}
	}, [router]);

	return (
		<>
			<Head>
				<title>Stride</title>
				<meta
					name="description"
					content="Unlock your peak performance with our cutting-edge athlete training software. Innovative features tailored for elite athletes. Designed by atheletes, for atheletes"
				/>
			</Head>
			<div className="container sticky top-4 z-10 flex justify-center">
				<motion.header
					className="rounded-xl bg-background"
					initial={{ width: "100%", filter: "none" }}
					animate={{
						width: smallNav ? "60%" : "100%",
						filter: smallNav
							? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1))"
							: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.0))",
					}}
					transition={{ duration: 2, width: { type: "spring", bounce: 0.2 } }}
				>
					<div className="px-4">
						<div className="flex items-center justify-between py-2">
							<div className="flex items-center gap-5">
								<Link href="/">
									<h1 className="font-stride text-2xl font-extrabold">
										S
										<span className={cn({ "hidden sm:inline": smallNav })}>
											TRIDE
										</span>
									</h1>
								</Link>
								<motion.div
									className="h-5"
									initial={{ opacity: 0 }}
									animate={{ opacity: smallNav ? 1 : 0 }}
									transition={{ duration: 2, type: "spring", bounce: 0 }}
								>
									<Separator orientation="vertical" />
								</motion.div>
							</div>
							<nav className="hidden gap-0 text-sm text-foreground/60 md:flex">
								<NavigationMenu>
									<NavigationMenuList>
										<NavigationMenuItem>
											<NavigationMenuTrigger>Features</NavigationMenuTrigger>
											<NavigationMenuContent>
												<ul className="grid gap-3 p-4 md:w-[400px] lg:w-[700px] lg:grid-cols-[.75fr_1fr_1fr]">
													<li className="row-span-3">
														<NavigationMenuLink asChild>
															<Link
																className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md"
																href="/"
															>
																{/* <Icons.logo className="h-6 w-6" /> */}
																<div className="mb-2 mt-4 text-lg font-medium">
																	Stride
																</div>
																<p className="text-sm leading-tight text-muted-foreground">
																	Designed by athletes, for athletes
																</p>
															</Link>
														</NavigationMenuLink>
													</li>
													<ListItem href="/#integrations" title="Integrations">
														Sync activities and metrics with your favourite apps
														and devices
													</ListItem>
													<ListItem href="/#overview" title="Overview">
														Track your progress and get valuable insights
													</ListItem>
													<ListItem href="/#ai" title="AI">
														FTP estimation, HRV trend analysis, and more
													</ListItem>
													<ListItem href="/#calendar" title="Calendar">
														Schedule your workouts and training
													</ListItem>
													<ListItem href="/#analysis" title="Analysis">
														Best in class analysis, including weather conditions
														and more
													</ListItem>
													<ListItem href="/#plans" title="Plans">
														Workouts and training plans, built by pros, or you.
													</ListItem>
												</ul>
											</NavigationMenuContent>
										</NavigationMenuItem>
										<NavigationMenuItem>
											<Link href="/#pricing" legacyBehavior passHref>
												<NavigationMenuLink
													className={navigationMenuTriggerStyle()}
												>
													Pricing
												</NavigationMenuLink>
											</Link>
										</NavigationMenuItem>
										<NavigationMenuItem>
											<Link href="/Changelog" legacyBehavior passHref>
												<NavigationMenuLink
													className={navigationMenuTriggerStyle()}
												>
													Changelog
												</NavigationMenuLink>
											</Link>
										</NavigationMenuItem>
									</NavigationMenuList>
								</NavigationMenu>
							</nav>
							<motion.div
								className="flex items-center justify-center gap-2 [--translate-from:0px] [--translate-to:0px] sm:[--translate-from:10px] sm:[--translate-to:0px]"
								initial={{ translate: "var(--translate-from)" }}
								animate={{
									translate: smallNav ? "0px" : "var(--translate-from)",
								}}
							>
								<div className="flex items-center justify-center gap-2">
									<div
										className={cn("flex items-center", { hidden: smallNav })}
									>
										<ModeToggle />
									</div>
									<Link
										className={buttonVariants({ variant: "ghost" })}
										href="/login"
									>
										Login
									</Link>
								</div>
								<motion.div
									initial={{ opacity: 0 }}
									animate={{
										opacity: smallNav ? 1 : 0,
									}}
									className={smallNav ? "" : "hidden"}
								>
									<Link
										className={buttonVariants({ variant: "default" })}
										href="/signup"
									>
										Sign Up
									</Link>
								</motion.div>
							</motion.div>
						</div>
					</div>
				</motion.header>
			</div>
			<div className="flex flex-col">
				<section className="space-y-6 pb-6 pt-6 md:pb-12 md:pt-10 lg:pb-14 lg:pt-14">
					<div className="flex justify-center text-center flex-col items-center">
						<div className="container flex flex-col items-center space-y-4">
							<h3 className="mt-4 text-2xl text-foreground/90 sm:text-5xl md:text-6xl lg:text-6xl tracking-tight font-semibold">
								<motion.span
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.8, ease: "easeOut" }}
								>
									{"Train Like a Pro".split("").map((char, index) => (
										<motion.span
											key={index}
											initial={{ opacity: 0, y: 20 }}
											animate={{ opacity: 1, y: 0 }}
											transition={{
												duration: 0.5,
												ease: "easeOut",
												delay: 0.05 * index,
											}}
										>
											{char === " " ? "\u00A0" : char}
										</motion.span>
									))}
								</motion.span>
							</h3>

							<h4 className="font-sans text-foreground w-1/3 text-lg">
								Train smarter and take your cycling to the next level with
								data-driven insights, workouts.
							</h4>
							<Link href="/signup">
								<div className="flex w-fit items-center gap-2 rounded-2xl border border-border px-3 py-1.5 text-xs font-medium">
									<p>
										<span className="mr-2">🎉</span>Free for 30 days!
									</p>
									<ArrowRightIcon className="h-4 w-4" />
								</div>
							</Link>
							<div className="flex gap-4 pt-2">
								<Link
									href="/signup"
									className={cn(
										buttonVariants({ variant: "default" }),
										"flex items-center",
									)}
								>
									Sign Up
									<ArrowRightIcon className="ml-2 h-4 w-4" />
								</Link>
								<Link
									href="/about"
									className={buttonVariants({ variant: "outline" })}
								>
									Learn More
								</Link>
							</div>
						</div>
						<div className="mt-10 flex items-center justify-between">
							<div className="flex items-center gap-4">
								<div className="flex -space-x-4">
									<div className="relative h-10 w-10 overflow-hidden rounded-full border-2 border-background bg-muted">
										<Image
											src={u5}
											alt="User 5"
											fill
											className="object-cover"
										/>
									</div>
									<div className="relative h-10 w-10 overflow-hidden rounded-full border-2 border-background bg-muted">
										<Image
											src={u4}
											alt="User 4"
											fill
											className="object-cover"
										/>
									</div>
									<div className="relative h-10 w-10 overflow-hidden rounded-full border-2 border-background bg-muted">
										<Image
											src={u3}
											alt="User 3"
											fill
											className="object-cover"
										/>
									</div>
									<div className="relative h-10 w-10 overflow-hidden rounded-full border-2 border-background bg-muted">
										<Image
											src={u2}
											alt="User 2"
											fill
											className="object-cover"
										/>
									</div>
									<div className="relative h-10 w-10 overflow-hidden rounded-full border-2 border-background bg-muted">
										<Image
											src={u1}
											alt="User 1"
											fill
											className="object-cover"
										/>
									</div>
								</div>
								<p className="text-xs text-muted-foreground w-32">
									Join the pack and don&apos;t get left behind.
								</p>
							</div>
						</div>
					</div>
				</section>

				<section className="space-y-6 pb-8 container flex justify-center items-center">
					<div className="hidden dark:block relative overflow-hidden border border-border rounded-xl p-2">
						<div className="relative rounded-xl overflow-hidden border-[1px] border-border px-1">
							<Image src={calDark} alt="Calendar" />
						</div>
					</div>
					<div className="dark:hidden border border-border rounded-xl p-2">
						<div className="relative rounded-xl overflow-hidden border-[1px] border-border px-1">
							<Image
								src={calLight}
								alt="Calendar"
								className="relative rounded-xl z-10"
							/>
						</div>
					</div>
				</section>

				<section
					className="space-y-2 pb-8 container flex justify-center items-center flex-col pt-10"
					id="integrations"
				>
					<p className="text-sm text-muted-foreground">
						Bring your data to Stride from your favourite apps and devices
					</p>
					<div className="w-full overflow-hidden">
						<motion.div
							className="flex items-center justify-center space-x-8"
							animate={{ opacity: 1 }}
							transition={{ duration: 0.8 }}
							viewport={{ once: true }}
							whileInView="visible"
							variants={{
								hidden: { opacity: 0 },
								visible: { opacity: 1 },
							}}
							initial="hidden"
						>
							<motion.div
								className="flex-shrink-0 relative h-16 w-32 bg-background rounded-lg p-2 flex items-center justify-center"
								initial={{ y: 20, opacity: 0 }}
								whileInView={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.5, delay: 0.1 }}
								viewport={{ once: true }}
							>
								<Image
									src={garmin}
									alt="Garmin"
									className="object-contain max-h-12 dark:invert"
								/>
							</motion.div>
							<motion.div
								className="flex-shrink-0 relative h-16 w-32 bg-background rounded-lg p-2 flex items-center justify-center"
								initial={{ y: 20, opacity: 0 }}
								whileInView={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.5, delay: 0.2 }}
								viewport={{ once: true }}
							>
								<Image
									src={strava}
									alt="Strava"
									className="object-contain max-h-12"
								/>
							</motion.div>
							<motion.div
								className="flex-shrink-0 relative h-16 w-32 bg-background rounded-lg p-2 flex items-center justify-center"
								initial={{ y: 20, opacity: 0 }}
								whileInView={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.5, delay: 0.3 }}
								viewport={{ once: true }}
							>
								<Image
									src={wahoo}
									alt="Wahoo"
									className="object-contain max-h-12 dark:invert mb-3"
								/>
							</motion.div>
							<motion.div
								className="flex-shrink-0 relative h-16 w-32 bg-background rounded-lg p-2 flex items-center justify-center"
								initial={{ y: 20, opacity: 0 }}
								whileInView={{ y: 0, opacity: 1 }}
								transition={{ duration: 0.5, delay: 0.4 }}
								viewport={{ once: true }}
							>
								<Image
									src={whoop}
									alt="Whoop"
									className="object-contain max-h-12 dark:invert mb-1"
								/>
							</motion.div>
						</motion.div>
					</div>
				</section>

				<section className="space-y-6 pb-8 container flex justify-center items-center flex-col pt-10">
					<div className="flex w-fit items-center gap-2 rounded-2xl border border-border px-3 py-1.5 text-xs font-medium">
						<p>
							<span className="mr-2">📈</span>Performance analysis
						</p>
						<ArrowRightIcon className="h-4 w-4" />
					</div>
					<div className="flex flex-col items-center justify-center">
						<h3 className="text-4xl font-semibold pb-4">
							Get the most out of your data
						</h3>
						<p className="text-muted-foreground">
							Our AI powered analysis tools help you get the most out of your
							data.
						</p>
					</div>
					<div className="flex flex-col items-center justify-center">
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl mt-10">
							{/* Fitness & Fatigue - Double width */}
							<div className="bg-background rounded-2xl border border-border p-6 shadow-sm md:col-span-2 overflow-hidden group hover:border-muted-foreground/60 transition-all">
								<div className="flex flex-col h-full">
									<div className="mb-4">
										<h4 className="text-xl font-semibold">Fitness & Fatigue</h4>
										<p className="text-muted-foreground mt-2">
											Track your training load over time with advanced fitness
											and fatigue modeling to optimize your performance.
										</p>
									</div>
									<div className="mt-auto pt-4 rounded-xl overflow-hidden bg-muted/30 aspect-[16/9] flex items-center justify-center">
										<div className="text-muted-foreground/70 text-sm">
											Fitness & Fatigue visualization
										</div>
									</div>
								</div>
							</div>

							{/* Multi Sport */}
							<div className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all">
								<div className="flex flex-col h-full">
									<div className="mb-4">
										<h4 className="text-xl font-semibold">Multi Sport</h4>
										<p className="text-muted-foreground mt-2">
											Seamlessly track and analyze all your activities across
											different sports in one unified platform.
										</p>
									</div>
									<div className="mt-auto pt-4 rounded-xl overflow-hidden bg-muted/30 aspect-[4/3] flex items-center justify-center">
										<div className="text-muted-foreground/70 text-sm">
											Multi sport dashboard
										</div>
									</div>
								</div>
							</div>

							{/* Power Curves */}
							<div className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all">
								<div className="flex flex-col h-full">
									<div className="mb-4">
										<h4 className="text-xl font-semibold">Power Curves</h4>
										<p className="text-muted-foreground mt-2">
											Visualize your power output across different durations to
											identify strengths and areas for improvement.
										</p>
									</div>
									<div className="mt-auto pt-4 rounded-xl overflow-hidden bg-muted/30 aspect-[4/3] flex items-center justify-center">
										<div className="text-muted-foreground/70 text-sm">
											Power curve graph
										</div>
									</div>
								</div>
							</div>

							{/* FTP Estimation */}
							<div className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all">
								<div className="flex flex-col h-full">
									<div className="mb-4">
										<h4 className="text-xl font-semibold">FTP Estimation</h4>
										<p className="text-muted-foreground mt-2">
											Get accurate FTP estimates based on your actual ride data
											without the need for dedicated testing protocols.
										</p>
									</div>
									<div className="mt-auto pt-4 rounded-xl overflow-hidden bg-muted/30 aspect-[4/3] flex items-center justify-center">
										<div className="text-muted-foreground/70 text-sm">
											FTP estimation chart
										</div>
									</div>
								</div>
							</div>

							{/* Metrics - Double height */}
							<div className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all">
								<div className="flex flex-col h-full">
									<div className="mb-4">
										<h4 className="text-xl font-semibold">Metrics</h4>
										<p className="text-muted-foreground mt-2">
											Integrate HRV, Weight, Sleep and other health metrics from
											Garmin, Whoop for a complete picture of your health.
										</p>
									</div>
									<div className="mt-auto pt-4 rounded-xl overflow-hidden bg-muted/30 aspect-[4/3] flex-1 flex items-center justify-center">
										<div className="text-muted-foreground/70 text-sm">
											Health metrics dashboard
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

        <section className="space-y-6 pb-8 container flex justify-center items-center flex-col pt-10">
					<div className="flex w-fit items-center gap-2 rounded-2xl border border-border px-3 py-1.5 text-xs font-medium">
						<p>
							<span className="mr-2">📋</span>Plans & Workouts
						</p>
						<ArrowRightIcon className="h-4 w-4" />
					</div>
					<div className="flex flex-col items-center justify-center">
						<h3 className="text-4xl font-semibold pb-4">
							Structured training made simple
						</h3>
						<p className="text-muted-foreground text-sm max-w-xl text-center">
							Create custom workouts and training plans or choose from our library of pre-built templates designed by professional coaches.
						</p>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-6xl mt-10">
						<motion.div 
							className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all"
							initial={{ y: 20, opacity: 0 }}
							whileInView={{ y: 0, opacity: 1 }}
							transition={{ duration: 0.5, delay: 0.1 }}
							viewport={{ once: true }}
						>
							<div className="flex flex-col h-full">
								<div className="mb-4">
									<h4 className="text-xl font-semibold">Smart Workouts</h4>
									<p className="text-muted-foreground mt-2">
										Create detailed workouts with power, heart rate, or RPE targets. Visualize your workout structure before you start.
									</p>
								</div>
								<div className="mt-auto pt-4 rounded-xl overflow-hidden bg-muted/30 aspect-[16/9] flex items-center justify-center">
									<div className="text-muted-foreground/70 text-sm">
										Workout builder visualization
									</div>
								</div>
							</div>
						</motion.div>

						<motion.div 
							className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all"
							initial={{ y: 20, opacity: 0 }}
							whileInView={{ y: 0, opacity: 1 }}
							transition={{ duration: 0.5, delay: 0.2 }}
							viewport={{ once: true }}
						>
							<div className="flex flex-col h-full">
								<div className="mb-4">
									<h4 className="text-xl font-semibold">Training Plans</h4>
									<p className="text-muted-foreground mt-2">
										Follow structured training plans that adapt to your schedule and fitness level. Track your progress and stay motivated.
									</p>
								</div>
								<div className="mt-auto pt-4 rounded-xl overflow-hidden bg-muted/30 aspect-[16/9] flex items-center justify-center">
									<div className="text-muted-foreground/70 text-sm">
										Training plan calendar view
									</div>
								</div>
							</div>
						</motion.div>
					</div>
				</section>
        
				<section className="space-y-6 pb-8 container flex justify-center items-center flex-col pt-10">
					<div className="flex w-fit items-center gap-2 rounded-2xl border border-border px-3 py-1.5 text-xs font-medium">
						<p>
							<span className="mr-2">🗺️</span>Activity analysis
						</p>
					</div>
					<div className="flex flex-col items-center justify-center">
						<h3 className="text-4xl font-semibold pb-4">
							Deep dive into your activity
						</h3>
						<p className="text-muted-foreground text-sm max-w-xl text-center">
							Analyze your workouts with comprehensive metrics including power,
							heart rate, cadence, speed and weather.
						</p>
					</div>

					<div className="w-full flex items-center justify-center pt-4">
						<div className="hidden dark:block relative overflow-hidden border border-border rounded-xl p-2">
							<div className="relative rounded-xl overflow-hidden border-[1px] border-border pl-1">
								<Image src={activityDark} alt="Activity View" />
							</div>
						</div>
						<div className="dark:hidden border border-border rounded-xl p-2">
							<div className="relative rounded-xl overflow-hidden border-[1px] border-border pl-1">
								<Image
									src={activityLight}
									alt="Activoty View"
									className="relative rounded-xl z-10"
								/>
							</div>
						</div>
					</div>
				</section>

	

				<section className="space-y-6 pb-8 container flex justify-center items-center flex-col pt-10" id="pricing">
					<div className="flex w-fit items-center gap-2 rounded-2xl border border-border px-3 py-1.5 text-xs font-medium">
						<p>
							<span className="mr-2">💰</span>Pricing
						</p>
						<ArrowRightIcon className="h-4 w-4" />
					</div>
					<div className="flex flex-col items-center justify-center">
						<h3 className="text-4xl font-semibold pb-4">
							Simple pricing
						</h3>
						<p className="text-muted-foreground">
							All features included.
						</p>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-4xl mt-10">
						<motion.div 
							className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all"
							initial={{ y: 20, opacity: 0 }}
							whileInView={{ y: 0, opacity: 1 }}
							transition={{ duration: 0.5, delay: 0.1 }}
							viewport={{ once: true }}
						>
							<div className="flex flex-col h-full">
								<div className="mb-4">
									<h4 className="text-xl font-semibold">For Athletes</h4>
									<div className="mt-4 flex items-baseline">
										<span className="text-3xl font-bold">£12</span>
										<span className="ml-1 text-muted-foreground">/month</span>
									</div>
									<p className="text-muted-foreground mt-4">
										All features included:
									</p>
									<ul className="mt-4 space-y-2">
										<li className="flex items-center">
											<span className="mr-2">✓</span> Workouts & Plans
										</li>
										<li className="flex items-center">
											<span className="mr-2">✓</span> Performance analysis
										</li>
                    <li className="flex items-center">
											<span className="mr-2">✓</span> Weather & Routes
										</li>
										<li className="flex items-center">
											<span className="mr-2">✓</span> All Integrations
										</li>
									</ul>
								</div>
								<div className="mt-auto pt-4">
									<Link 
										href="/signup" 
										className={cn(buttonVariants({ size: "lg" }), "w-full")}
									>
										Get started
									</Link>
								</div>
							</div>
						</motion.div>

						<motion.div 
							className="bg-background rounded-2xl border border-border p-6 shadow-sm overflow-hidden group hover:border-muted-foreground/60 transition-all"
							initial={{ y: 20, opacity: 0 }}
							whileInView={{ y: 0, opacity: 1 }}
							transition={{ duration: 0.5, delay: 0.2 }}
							viewport={{ once: true }}
						>
							<div className="flex flex-col h-full">
								<div className="mb-4">
									<h4 className="text-xl font-semibold">For Coaches</h4>
									<p className="text-muted-foreground mt-4">
										£12/month per athlete
									</p>
                  <div className="mt-4 flex items-baseline">
										<span className="text-muted-foreground">No minimum fee</span>
									</div>
									<ul className="mt-4 space-y-2">
										<li className="flex items-center">
											<span className="mr-2">✓</span> Athlete dashboard
										</li>
										<li className="flex items-center">
											<span className="mr-2">✓</span> Create & manage plans, workouts & more
										</li>
                    <li className="flex items-center">
											<span className="mr-2">✓</span> Unlimited Teams
										</li>
										<li className="flex items-center">
											<span className="mr-2">✓</span> All athlete features included
										</li>
									</ul>
								</div>
								<div className="mt-auto pt-4">
									<Link 
										href="/signup" 
										className={cn(buttonVariants({ size: "lg" }), "w-full")}
									>
										Start coaching
									</Link>
								</div>
							</div>
						</motion.div>
					</div>
				</section>

				<footer className="container col-span-3 space-y-6 px-6 py-8 sm:px-0 md:py-12 lg:py-24">
					<Separator />
					<div className="grid grid-cols-2">
						<div className="space-y-2">
							<h1 className="space-x-2 font-stride text-2xl font-bold">
								STRIDE
							</h1>
							<p className="text-xs text-muted-foreground">
								71-75, Shelton Street <br />
								Covent Garden <br />
								London,
								<br />
								WC2H 9JQ
							</p>
							<p className="text-xs text-muted-foreground">
								© 2024 STRIDE TECHNOLOGY LTD
							</p>
						</div>
						<div className="flex justify-end">
							<div className="mr-9 space-y-2">
								<h4 className="font-bold">About</h4>
								<ul className="text-sm text-muted-foreground space-y-2">
									<li>
										<a href="mailto:support@stride.is">Contact</a>
									</li>
									<li>
										<a href="/privacy.html">Privacy Policy</a>
									</li>

									<li>
										<a href="/tos.html">Terms and Conditions</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</footer>
				<LiveChatScript />
			</div>
		</>
	);
}

const ListItem = React.forwardRef<
	React.ElementRef<"a">,
	React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
	return (
		<li>
			<NavigationMenuLink asChild>
				<a
					ref={ref}
					className={cn(
						"block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
						className,
					)}
					{...props}
				>
					<div className="text-sm font-medium leading-none">{title}</div>
					<p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
						{children}
					</p>
				</a>
			</NavigationMenuLink>
		</li>
	);
});
ListItem.displayName = "ListItem";
